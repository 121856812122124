@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
  }

  .Login .lander {
    padding: 10px 0;
    text-align: center;
  }

  .Login .lander h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }