@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  .Signup form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
  }

  .Signup .lander {
    padding: 10px 0;
    text-align: center;
  }

  .Signup .lander h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }