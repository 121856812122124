.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .Home .lander p {
    color: #666;
    font-size: 14px;
  }

  .Home .notes h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .Home .notes p {
    color: #666;
  }
  
 .Home a {
  color: #343a40;
}

.Home a:hover {
  color: #173798;
  text-decoration: none;
}

.Home div.media {
  margin-top: 30px;
  margin-bottom: 10px;
}

.Home div.media .media-heading .jobTotalDuration {
  color: #666;
  font-size: 14px;
  display: block;
}

.Home div.media .logo-experience img {
  height: 4vmin;
  margin-right: 10px;
  border-radius: 50px;
}

.Home div.media img {
  height: 5vmin;
  margin-right: 10px;
  border-radius: 50px;
}

.Home div.media .jobDuration {
  color: #444;
  display: block;
}

.Home div.media .jobLocation {
  color: #666;
}

.Home div.row {
  margin-left: 20px;
}

.Home .formLabel {
  font-weight: bold;
}

.Home .certificateLink {
  color: cornflowerblue;
  font-weight: bold;
}
 
.Home  h2 {
  font: 22px/30px 'opensans-bold', sans-serif;
  font-weight: bold;
}

.Home  h3 {
  font: 15px/24px 'opensans-bold', sans-serif;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.Home p {
  font: 15px/24px 'opensans-bold', sans-serif;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.Home li {
  font: 13px/36px 'opensans-regular', Sans-serif;
  color: #474747 !important;
  border: none;
}

.Home footer {
margin-top: 20px;
margin-bottom: 10px;
border-top: #444444;
text-align: center;
}

.Home footer a {
font-size: 30px;
margin-right: 10px;
margin-left: 10px;
}

.Home footer hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
      border-top-color: currentcolor;
      border-top-style: none;
      border-top-width: 0px;
  border-top: 1px solid darkgray;
}

.Home .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.Home .banner-text { 
  width: 100%; 
  text-align: center;
}
header .banner-text h1 {
   font: 90px/1.1em 'opensans-bold', sans-serif;
   color: #fff;
   letter-spacing: -2px;
   margin: 0 auto 18px auto;
   text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
   text-align: center;
}
.Home .banner-text h3 {
   font: 28px/1.9em 'librebaskerville-regular', serif;
   color: black;
   margin: 0 auto;
   width: 80%;
   text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
}
.Home .banner-text h3 span, header .banner-text h3 a {
   color: darkred;
}

.Home .banner-text h4 {
  font: 20px/1.9em 'librebaskerville-regular', serif;
  color: black;
  margin: 0 auto;
  width: 80%;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
}

.Home .banner-text h4 a {
  font: 20px/1.9em 'librebaskerville-regular', serif;
  color:#2e3f8b;
  font-weight: bold;
  float:none;
 
}

.Home .banner-text h4 span {
  color: darkred;
}
.Home .banner-text hr {
   width: 60%;
   margin: 18px auto 24px auto;
   border-color: #2F2D2E;
   border-color: rgba(150, 150, 150, .1);
}

.Home .banner-text .navbar-brand {

  color: #2e3f8b;
  font-weight: bold;
  font-size: 18px;
  float:none;
  
}

.Home mainpage-img {
  border: 0;
  border-radius: 4px;
  padding: 5px;
  width: 1000px;
  position: center;
}
