.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

/* making the top navigation bar's background and border invisible */
.App .navbar{
  font-weight: bold;
  background-color:transparent !important; 
  border:0;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  background-color:transparent !important; 
  border:0;
  overflow: hidden; /* Hide scrollbars */
}

.App .navbar-default {
  font-size: 14px;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active {
font-weight: italic bold;
font-size: large;
font: italic bold;
/*background-color:#5bc0de !important;
border:0;
border-radius: 20px;
*/

border:0;
background-color:transparent !important; 
/*
border-bottom: 1px solid #9fbfdf;
*/
}

a.active.nav-link {
  font-weight: bold;
 /* background-color: #9fbfdf !important;*/
  background-color:#5bc0de !important;
  border-radius: 20px; 
  border-color: #46b8da;
}

.jumbotron {
  background-color:transparent !important; 
}

.banner-text{
  font: 90px/1.1em 'opensans-bold', sans-serif;
   letter-spacing: -2px;
   margin: 0 auto 18px auto;
}


.table thead {
  background-color: #5bc0de;
}